@import '../partials';

.blockWysiwyg {
  @include content-container;

  @include bp(lg) {
    padding-right: rem($interior-padding);
  }

  &__boundary {
    @include interior-content-container;

    .single-artist &,
    .single-artwork & {
      max-width: rem(684);
    }

    img {
      max-width: 100%;

      &.alignleft {
        margin-left: 0;
        margin-right: auto;
        display: block;
      }

      &.aligncenter {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      &.alignright {
        margin-left: auto;
        margin-right: 0;
        display: block;
      }
    }
  }
}
